// React Components Imports
import * as React from "react";

// Internal Imports
import { MainLayoutMainContainerTOC, MainLayoutCodeSample } from "@technologyone/t1-dxp-component-designsystem";

// Props/State Interface
interface TokensTabProps {}

// Component Definition
class TokensTab extends React.Component<TokensTabProps> {
    
    render() {
        return (
            <MainLayoutMainContainerTOC>
                <div className="tokensPage"></div>
                <h2 id="BordersTokens">Borders</h2>
                <div aria-hidden="true" className="h2 headingSticky">Borders</div>
                <div className="ds-tokensPage-section borderTokens">
                    <div className="ds-tokens-TableHeadings">
                        <div>{/* Example */}</div>
                        <div>Design Token</div>
                        <div>Palette Token Variable(s)</div>

                    </div>
                    <div className="ds-tokens-TableBody">
                        <DesignToken
                            token="borderActive"
                            value="var(--borderWidthSmall) solid var(--colorBorderInputActive)"
                            style="border"
                        />
                        <DesignToken
                            token="borderDefault"
                            value="var(--borderWidthSmall) solid var(--colorBorderInputDefault)"
                            style="border"
                        />
                        <DesignToken
                            token="borderError"
                            value="var(--borderWidthSmall) solid var(--colorError)"
                            style="border"
                        />
                        <DesignToken
                            token="borderError"
                            value="var(--borderWidthSmall) solid var(--colorError)"
                            style="border"
                        />
                        <DesignToken
                            token="borderFocus"
                            value="var(--borderWidthSmall) solid var(--colorBlueDark)"
                            style="border"
                        />
                        <DesignToken
                            token="borderPointer"
                            value="var(--borderWidthSmall) solid var(--colorBorderHighlight)"
                            style="border"
                        />
                        <DesignToken
                            token="borderDropZone"
                            value="var(--borderWidthSmall) dashed var(--colorBorderHighlight)"
                            style="border"
                        />
                        <DesignToken
                            token="borderEditorDefault"
                            value="var(--borderWidthSmall) dashed var(--colorBorderInputDefault)"
                            style="border"
                        />
                        <DesignToken
                            token="borderEditorActive"
                            value="var(--borderWidthSmall) dashed var(--colorNeutralDarker)"
                            style="border"
                        />
                    </div>
                </div>

                <h2 id="borderRadiusTokens">Border Radius</h2>
                <div aria-hidden="true" className="h2 headingSticky">Border Radius</div>
                <div className="ds-tokensPage-section borderRadiusTokens">
                    <div className="ds-tokens-TableHeadings">
                        <div>{/* Example */}</div>
                        <div>Design Token</div>
                        <div>Palette Token Variable</div>
                    </div>
                    <div className="ds-tokens-TableBody">
                        <DesignToken
                            token="borderRadiusContainer"
                            value="var(--borderRadiusMedium)"
                            style="borderRadius"
                        />
                        <DesignToken
                            token="borderRadiusInput"
                            value="var(--borderRadiusMedium)"
                            style="borderRadius"
                        />
                        <DesignToken
                            token="borderRadiusButton"
                            value="var(--borderRadiusMedium)"
                            style="borderRadius"
                        />
                        <DesignToken
                            token="borderRadiusLens"
                            value="var(--borderRadiusCircle)"
                            style="borderRadius"
                        />
                    </div>
                </div>

                <h2 id="ColoursTokens">Colours</h2>
                <div aria-hidden="true" className="h2 headingSticky">Colours</div>
                <div className="ds-tokensPage-section coloursTokens">
                    <div className="ds-tokens-TableHeadings">
                        <div>{/* Swatch */}</div>
                        <div>Design token</div>
                        <div>Palette Token Variable</div>
                    </div>
                    <div className="ds-tokens-TableBody">
                        <div className="coloursNeutral">
                            <div className="ds-tokens-TableCategoryHeading">Neutral Colours</div>
                            <DesignToken
                                token="colorNeutralDarkest"
                                value="var(--colorPrimaryDarkest)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorNeutralDarker"
                                value="var(--colorPrimaryDarker)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorNeutralDark"
                                value="var(--colorPrimaryDark)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorNeutral"
                                value="var(--colorPrimary)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorNeutralLight"
                                value="var(--colorPrimaryLight)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorNeutralLighter"
                                value="var(--colorPrimaryLighter)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorNeutralLighter-50"
                                value="var(--colorPrimaryLighter-50)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorNeutralLightest"
                                value="var(--colorPrimaryLightest)"
                                style="backgroundColor"
                            />
                        </div>
                        <div className="coloursForeground">
                            <div className="ds-tokens-TableCategoryHeading">Foreground Colours</div>
                            <DesignToken
                                token="colorLightest"
                                value="var(--colorWhite)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorDarkest"
                                value="var(--colorBlack)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorError"
                                value="var(--colorRedDark)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorMandatory"
                                value="var(--colorYellowDark)"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursBackgrounds">
                            <div className="ds-tokens-TableCategoryHeading">Background Colours</div>
                            <DesignToken
                                token="colorBackgroundAltDefault"
                                value="var(--colorNeutralLightest)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorBackgroundDefault"
                                value="var(--colorWhite)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorBackgroundDisabled"
                                value="var(--colorNeutralLightest)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorBackgroundError"
                                value="var(--colorError)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorBackgroundHighlight"
                                value="var(--colorIndigoLightest)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorBackgroundLayoutInverseDefault"
                                value="var(--colorNeutralDarker)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorBackgroundLayoutInverseAltDefault"
                                value="var(--colorNeutralDarkest)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorBackgroundInputChecked"
                                value="var(--colorPrimaryDark)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorBackgroundInverse"
                                value="var(--colorBlackOpac-15)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorBackgroundInverseHover"
                                value="var(--colorBlackOpac-30)"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursBorderInput">
                            <div className="ds-tokens-TableCategoryHeading">Input Border Colours</div>
                            <DesignToken
                                token="colorBorderInputDefault"
                                value="var(--colorNeutralLight)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorBorderInputHover"
                                value="var(--colorNeutralDark)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorBorderInputActive"
                                value="var(--colorNeutralDarker)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorBorderInputDisabled"
                                value="var(--colorNeutralLighter)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorBorderInputChecked"
                                value="var(--colorPrimaryDark)"
                                style="backgroundColor"
                            />
                        </div>
                        <div className="coloursBorderHighlight">
                            <div className="ds-tokens-TableCategoryHeading">Border Highlight Colour</div>
                            <DesignToken
                                token="colorBorderHighlight"
                                value="var(--colorIndigoDark)"
                                style="backgroundColor"
                            />
                        </div>
                        <div className="coloursButton">
                            <div className="ds-tokens-TableCategoryHeading">Button Colours</div>
                            <DesignToken
                                token="colorButtonDefault"
                                value="var(--colorPrimaryDark)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorButtonHover"
                                value="var(--colorPrimaryDarker)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorButtonDisabled"
                                value="var(--colorNeutralLighter)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorButtonInverseDefault"
                                value="var(--colorLightest)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorButtonInverseHover"
                                value="var(--colorBlackOpac-20)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorButtonInverseDisabled"
                                value="var(--colorNeutralDark)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorButtonInverseTextDisabled"
                                value="var(--colorNeutralLight)"
                                style="backgroundColor"
                            />
                        </div>
                        <div className="coloursText">
                            <div className="ds-tokens-TableCategoryHeading">Text Colours</div>
                            <DesignToken
                                token="colorTextActive"
                                value="var(--colorNeutralDarkest)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorTextDateCurrent"
                                value="var(--colorTealDark)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorTextDatePast"
                                value="var(--colorNeutralDarker)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorTextDefault"
                                value="var(--colorNeutralDarker)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorTextDefaultInverse"
                                value="var(--colorWhite)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorTextDisabled"
                                value="var(--colorNeutral)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorTextError"
                                value="var(--colorWhite)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorTextLink"
                                value="var(--colorBlueDark)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorTextLinkActive"
                                value="var(--colorBlueDarkest)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorTextNumber"
                                value="var(--colorIndigoDark)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorTextLabelReadOnly"
                                value="var(--colorNeutralDark)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorTextPlaceholder"
                                value="var(--colorNeutral)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorTextSubtle"
                                value="var(--colorNeutralDark)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorTextCountWarning"
                                value="var(--colorError)"
                                style="backgroundColor"
                            />
                        </div>
                        <div className="coloursIcon">
                            <div className="ds-tokens-TableCategoryHeading">Icon Colours</div>
                            <DesignToken
                                token="colorIconClear"
                                value="var(--colorNeutral)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorIconClearHover"
                                value="var(--colorNeutralDarker)"
                                style="backgroundColor"
                            />
                        </div>
                        <div className="coloursPicker">
                            <div className="ds-tokens-TableCategoryHeading">Picker Colours</div>
                            <DesignToken
                                token="colorPickerButton"
                                value="var(--colorNeutralDark)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorPickerButtonHover"
                                value="var(--colorWhite)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorPickerButtonBackground"
                                value="var(--colorTransparent)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorPickerButtonBackgroundHover"
                                value="var(--colorPrimaryDark)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorPickerButtonDisabled"
                                value="var(--colorBorderInputDisabled)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorSuggestionItemBackgroundHover"
                                value="var(--colorNeutralLightest)"
                                style="backgroundColor"
                            />
                        </div>
                        <div className="coloursArc">
                            <div className="ds-tokens-TableCategoryHeading">Arc Colours</div>
                            <DesignToken
                                token="colorArc"
                                value="var(--colorYellow)"
                                style="backgroundColor"
                            />
                            <DesignToken
                                token="colorArcInverse"
                                value="var(--colorYellow)"
                                style="backgroundColor"
                            />
                        </div>
                    </div>
                </div>

                <h2 id="SizesTokens">Sizes</h2>
                <div aria-hidden="true" className="h2 headingSticky">Sizes</div>
                <div className="ds-tokens-Cntr sizesTokens">
                    <div className="ds-tokens-TableHeadings">
                        <div>Design token</div>
                        <div>Palette Token Variable</div>
                    </div>
                    <div className="sizesWidth">
                        <div className="ds-tokens-TableBody">
                            <div className="ds-tokens-TableCategoryHeading">Width Sizes</div>
                            <DesignToken
                                token="widthButtonSmall"
                                value="var(--widthMedium-60)"
                                style="width"
                            />
                            <DesignToken
                                token="widthButtonMedium"
                                value="var(--widthMedium-70)"
                                style="width"
                            />
                            <DesignToken
                                token="widthButtonLarge"
                                value="var(--widthLarge-20)"
                                style="width"
                            />
                            <DesignToken
                                token="widthButtonLarger"
                                value="var(--widthLarger)"
                                style="width"
                            />
                            <DesignToken
                                token="widthButtonInput"
                                value="var(--widthSmall-30)"
                                style="width"
                            />
                            <DesignToken
                                token="widthPickerButton"
                                value="var(--widthSmall-60)"
                                style="width"
                            />
                            <DesignToken
                                token="widthInputSmall"
                                value="var(--widthLarge-10)"
                                style="width"
                            />
                            <DesignToken
                                token="widthInputMedium"
                                value="var(--widthLarge-45)"
                                style="width"
                            />
                            <DesignToken
                                token="widthInputLarge"
                                value="var(--widthLarge-90)"
                                style="width"
                            />
                            <DesignToken
                                token="widthLensSmall"
                                value="var(--widthSmall-40)"
                                style="width"
                            />
                            <DesignToken
                                token="widthCardLensMedium"
                                value="var(--heightMedium-30)"
                                style="width"
                            />
                            <DesignToken
                                token="widthCardTileSeparator"
                                value="var(--widthSmall-20)"
                                style="width"
                            />
                        </div>
                    </div>
                    <div className="sizesHeight">
                        <div className="ds-tokens-TableBody">
                            <div className="ds-tokens-TableCategoryHeading">Button Height Sizes</div>
                            <DesignToken
                                token="heightButtonSmall"
                                value="var(--heightSmall-50)"
                                style="height"
                            />
                            <DesignToken
                                token="heightButtonMedium"
                                value="var(--heightSmall-60)"
                                style="height"
                            />
                            <DesignToken
                                token="heightButtonLarge"
                                value="var(--heightMedium-20)"
                                style="height"
                            />
                            <DesignToken
                                token="heightButtonLarger"
                                value="var(--heightMedium-30)"
                                style="height"
                            />
                            <DesignToken
                                token="heightButtonInput"
                                value="var(--heightSmall-30)"
                                style="height"
                            />
                            <DesignToken
                                token="heightPickerButton"
                                value="var(--heightSmall-60)"
                                style="height"
                            />

                            <div className="ds-tokens-TableCategoryHeading">Breadcrumb Height Size</div>
                            <DesignToken
                                token="heightBreadcrumbItem"
                                value="var(--heightSmall-40)"
                                style="height"
                            />

                            <div className="ds-tokens-TableCategoryHeading">Input Height Sizes</div>
                            <DesignToken
                                token="heightInputSmall"
                                value="var(--heightSmall-50)"
                                style="height"
                            />
                            <DesignToken
                                token="heightInputMedium"
                                value="var(--heightSmall-60)"
                                style="height"
                            />
                            <DesignToken
                                token="heightInputLarge"
                                value="var(--heightMedium-10)"
                                style="height"
                            />
                            <DesignToken
                                token="heightInputLarger"
                                value="var(--heightMedium-20)"
                                style="height"
                            />
                            <DesignToken
                                token="heightSuggestionItem"
                                value="var(--heightSmall-60)"
                                style="height"
                            />

                            <div className="ds-tokens-TableCategoryHeading">Lense Height Sizes</div>
                            <DesignToken
                                token="heightLensSmall"
                                value="var(--heightSmall-40)"
                                style="height"
                            />
                            <DesignToken
                                token="heightLensMedium"
                                value="var(--heightMedium-30)"
                                style="height"
                            />
                            <div className="ds-tokens-TableCategoryHeading">Card Tile Separator Height Size</div>
                            <DesignToken
                                token="heightCardTileSeparator"
                                value="var(--heightSmall-20)"
                                style="height"
                            />
                        </div>
                    </div>
                </div>

                <h2 id="SpacingTokens">Spacing</h2>
                <div aria-hidden="true" className="h2 headingSticky">Spacing</div>
                <div className="ds-tokens-Cntr spacingTokens">
                    <div className="ds-tokens-TableHeadings">
                        <div></div>
                        <div>Design token</div>
                        <div>Value</div>
                    </div>
                    <div className="ds-tokens-TableBody">
                        <div className="spacingHeader">
                            <div className="ds-tokens-TableCategoryHeading">Header Spacing</div>
                            <DesignToken
                                token="headerGuttering"
                                value="var(--spacingBase-50)"
                                style="padding"
                            />
                        </div>
                        <div className="spacingTextInput">
                            <div className="ds-tokens-TableCategoryHeading">Text Input Spacing</div>
                            <DesignToken
                                token="paddingTextInput"
                                value="var(--spacingBase-10)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingTextInputSides"
                                value="var(--spacingTight-80)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingTextInputCharacterCount"
                                value="var(--spacingTight-40)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingTextInputError"
                                value="var(--spacingTight-20)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingInputErrorTopBottom"
                                value="var(--spacingTight-20)"
                                style="padding"
                            />

                            <DesignToken
                                token="paddingInputErrorLeftRight"
                                value="var(--spacingTight-80)"
                                style="padding"
                            />
                        </div>
                        <div className="spacingTextContent">
                            <div className="ds-tokens-TableCategoryHeading">Text Content Spacing</div>
                            <DesignToken
                                token="paddingTextContent"
                                value="var(--spacingTight-80)"
                                style="padding"
                            />
                        </div>
                        <div className="spacingButtonInput">
                            <div className="ds-tokens-TableCategoryHeading">Button Input Spacing</div>
                            <DesignToken
                                token="paddingButtonInput"
                                value="var(--spacingTight-80)"
                                style="padding"
                            />
                        </div>
                        <div className="spacingCard">
                            <div className="ds-tokens-TableCategoryHeading">Card Spacing</div>
                            <DesignToken
                                token="paddingCardTile"
                                value="var(--spacingTight-80)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingCardTileLens"
                                value="var(--spacingTight-40)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingCardTileContext"
                                value="var(--spacingTight-40)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingCardTileSecondaryLine"
                                value="var(--spacingTight-40)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingCardHorizontalSide"
                                value="var(--spacingBase-100)"
                                style="padding"
                            />
                        </div>
                        <div className="spacingContainer">
                            <div className="ds-tokens-TableCategoryHeading">Container Spacing</div>
                            <DesignToken
                                token="paddingPanelContainerBottom"
                                value="var(--spacingBase-40)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingPanelContainerInternal"
                                value="var(--spacingBase-10)"
                                style="padding"
                            />
                        </div>
                        <div className="spacingPanelPhone">
                            <div className="ds-tokens-TableCategoryHeading">Panel Phone Spacing</div>
                            <DesignToken
                                token="paddingPanelPhone"
                                value="var(--spacingTIght-80) var(--spacingBase-10)"
                                style="padding"
                            />
                        </div>
                        <div className="spacingContent">
                            <div className="ds-tokens-TableCategoryHeading">Content Spacing</div>
                            <DesignToken
                                token="paddingContentList"
                                value="var(--spacingBase-100) var(--spacingLoose-10)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingContentPhone"
                                value="var(--spacingBase-40) var(--spacingBase-10)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingContentRow"
                                value="var(--spacingBase-10) var(--spacing-none)"
                                style="padding"
                            />
                        </div>
                        <div className="spacingContainer">
                            <div className="ds-tokens-TableCategoryHeading">Container Spacing</div>
                            <DesignToken
                                token="paddingContainer"
                                value="var(--spacingTight-80) var(--spacingBase-10)"
                                style="padding"
                            />
                        </div>
                        <div className="spacingTab">
                            <div className="ds-tokens-TableCategoryHeading">Tab Spacing</div>
                            <DesignToken
                                token="paddingTabSide"
                                value="var(--spacingBase-40)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingTabTop"
                                value="var(--spacingBase-20)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingTabBottom"
                                value="var(--spacing-none)"
                                style="padding"
                            />
                        </div>
                        <div className="spacingCarousel">
                            <div className="ds-tokens-TableCategoryHeading">Carousel Spacing</div>
                            <DesignToken
                                token="paddingCarouselVerticalSide"
                                value="var(--spacingBase-50)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingTopCarouselItems"
                                value="var(--spacingBase-50)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingBottomCarouselItems"
                                value="var(--spacingTight-40)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingBodyHorizontalSide"
                                value="var(--spacingLoose-20)"
                                style="padding"
                            />
                        </div>
                        <div className="spacingCarousel">
                            <div className="ds-tokens-TableCategoryHeading">Gutter Spacing</div>
                            <DesignToken
                                token="paddingGutterHorizontalSide"
                                value="var(--spacingBase-20)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingGutterSmall"
                                value="var(--paddingGutterSmallVertical) var(--paddingGutterSmallHorizontal)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingGutterSmallVertical"
                                value="var(--spacing-none)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingGutterSmallHorizontal"
                                value="var(--spacingTight-20)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingGutterStatusLabel"
                                value="var(--spacingTight-40)"
                                style="padding"
                            />
                        </div>
                        <div className="spacingStepper">
                            <div className="ds-tokens-TableCategoryHeading">Stepper Spacing</div>
                            <DesignToken
                                token="paddingStepper"
                                value="var(--spacingTight-80) var(--spacingBase-40)"
                                style="padding"
                            />
                        </div>
                        <div className="spacingTooltip">
                            <div className="ds-tokens-TableCategoryHeading">Tooltip Spacing</div>
                            <DesignToken
                                token="paddingTooltipVertical"
                                value="var(--spacingTight-60)"
                                style="padding"
                            />
                            <DesignToken
                                token="paddingTooltipHorizontal"
                                value="var(--spacingTight-80)"
                                style="padding"
                            />
                        </div>
                        <div className="spacingMandatory">
                            <div className="ds-tokens-TableCategoryHeading">Mandatory Spacing</div>
                            <DesignToken
                                token="paddingMandatoryIndicator"
                                value="var(--spacingTight-40)"
                                style="padding"
                            />
                        </div>
                    </div>
                </div>

                <h2 id="TypographyTokens">Typography</h2>
                <div aria-hidden="true" className="h2 headingSticky">Typography</div>
                <div className="ds-tokensPage-section typographyTokens">
                    <div className="ds-tokens-TableHeadings">
                        <div>{/* Example */}</div>
                        <div>Design Token</div>
                        <div>Palette Token Variable(s)</div>

                    </div>
                    <div className="ds-tokens-TableBody">
                        <div className="typographyFontFamily">
                            <div className="ds-tokens-TableCategoryHeading">Font Family Typography</div>
                            <DesignToken
                                token="fontFamilyPrimary"
                                value="var(--fontFamilyBase)"
                                style="fontFamily"
                            />
                            <DesignToken
                                token="fontFamilySecondary"
                                value="var(--fontFamilyHeading)"
                                style="fontFamily"
                            />
                        </div>
                        <div className="typographyFontSizeBase">
                            <div className="ds-tokens-TableCategoryHeading">Font Size Base Typography</div>
                            <DesignToken
                                token="fontSizeBase"
                                value="var(--fontSizeBaseDesktop)"
                                style="fontSize"
                            />
                            <DesignToken
                                token="fontSizeBodyLineHeight"
                                value="var(--lineHeightSmallest)"
                                style="lineHeight"
                            />
                        </div>
                        <div className="typographyHelp">
                            <div className="ds-tokens-TableCategoryHeading">Help Text Typography</div>
                            <DesignToken
                                token="fontHelpText"
                                value="var(--fontSizeSmaller)"
                                style="fontSize"
                            />
                            <DesignToken
                                token="fontHelpTextLineHeight"
                                value="var(--lineHeightSmaller)"
                                style="lineHeight"
                            />

                        </div>
                        <div className="typographyFontWeight">
                            <div className="ds-tokens-TableCategoryHeading">Font Weight Typography</div>
                            <DesignToken
                                token="fontWeightActive"
                                value="var(--fontWeightRegular)"
                                style="fontWeight"
                            />
                            <DesignToken
                                token="fontWeightDateCurrent"
                                value="var(--fontWeightRegular)"
                                style="fontWeight"
                            />
                            <DesignToken
                                token="fontWeightDatePast"
                                value="var(--fontWeightRegular)"
                                style="fontWeight"
                            />
                            <DesignToken
                                token="fontWeightDefault"
                                value="var(--fontWeightRegular)"
                                style="fontWeight"
                            />
                            <DesignToken
                                token="fontWeightDisabled"
                                value="var(--fontWeightRegular)"
                                style="fontWeight"
                            />
                            <DesignToken
                                token="fontWeightEmphasis"
                                value="var(--fontWeightRegular)"
                                style="fontWeight"
                            />
                            <DesignToken
                                token="fontWeightLink"
                                value="var(--fontWeightMedium)"
                                style="fontWeight"
                            />
                            <DesignToken
                                token="fontWeightLinkActive"
                                value="var(--fontWeightMedium)"
                                style="fontWeight"
                            />
                            <DesignToken
                                token="fontWeightPlaceholder"
                                value="var(--fontWeightRegular)"
                                style="fontWeight"
                            />
                            <DesignToken
                                token="fontWeightNumber"
                                value="var(--fontWeightRegular)"
                                style="fontWeight"
                            />
                            <DesignToken
                                token="fontWeightSubtle"
                                value="var(--fontWeightRegular)"
                                style="fontWeight"
                            />
                        </div>
                        <div className="typographyFontCard">
                            <div className="ds-tokens-TableCategoryHeading">Card Font Typography</div>
                            <DesignToken
                                token="fontWeightCardSubHeading"
                                value="var(--fontWeightRegular)"
                                style="fontWeight"
                            />
                            <DesignToken
                                token="fontStyleCardSubHeading"
                                value="italic"
                                style="fontStyle"
                            />
                            <DesignToken
                                token="fontStyleCardTag"
                                value="normal"
                                style="fontStyle"
                            />
                            <DesignToken
                                token="fontStyleCardSubTag"
                                value="italic"
                                style="fontStyle"
                            />
                        </div>
                        <div className="typographyFontEmphasis">
                            <div className="ds-tokens-TableCategoryHeading">Font Emphasis Typography</div>
                            <DesignToken
                                token="fontStyleEmphasis"
                                value="italic"
                                style="fontStyle"
                            />
                            <DesignToken
                                token="fontTransformEmphasis"
                                value="uppercase"
                                style="textTransform"
                            />

                        </div>


                    </div>
                </div>
            </MainLayoutMainContainerTOC>
        );
    }
}

export default TokensTab;
export { TokensTabProps };

const DesignToken: React.FunctionComponent<{token: string, value: string, style: keyof React.CSSProperties}> =
({token, value, style}) => (
    <div className="ds-tokens-TableRow">
        <div className="swatchCntr">
            <div className={`swatch`}>
                <div className="swatchInner" style={{[style]: `var(--${token})`}}>
                    <div className="swatchInner2">
                        <div className="swatchInner3">
                            <div className="swatchInner4"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="token">
            <span>--</span>{token}
        </div>

        <div className="value">
            {value}
        </div>

    </div>
);

/* const DiagramDesignToken: React.FunctionComponent<{token: string, labelBold: string, label: string, value: string, style: keyof React.CSSProperties}> =
({ token, labelBold, label, value, style }) => (
    <div className="itemCntr">
        <div className="label"><b>{labelBold}</b> {label}</div>
        <div className="valueCntr">
            <div className="bg">
                <div className="swatchCntr">
                    <div className="swatch">
                        <div className="swatchInner" style={{ [style]: `var(--${token})` }}></div>
                    </div>
                </div>
                <div className="value">{value}</div>
            </div>
        </div>
    </div>
); */
